import { Button, Modal, message, Progress, Tooltip } from 'antd';
import type { BaseData, IMainSubStructureModel, NormalProgrammeParams } from '../../utils/index';
import { request, SearchListModal } from '../../utils/index';
import JSZip from 'jszip';
import { action, observable } from 'mobx';
import moment from 'moment';
import qs from 'qs';
import React from 'react';
import styles from './index.less';
import type { Config, StartAndEndTime, ImportConfig } from './interface';
import { ExportStore } from '../../compnents/exportModal';
// 导出进度
const exportStatusList = [
  {
    label: '等待导出',
    value: '0',
  },
  {
    label: '正在导出',
    value: '1',
  },
  {
    label: '导出成功',
    value: '2',
  },
  {
    label: '导出失败',
    value: '3',
  },
];

const dateList = [
  {
    label: '今天',
    value: 'today',
  },
  {
    label: '昨天',
    value: 'yesterday',
  },
  {
    label: '前天',
    value: 'dayBeforeYesterday',
  },
];

const importStatusList = [
  {
    label: '等待导入',
    value: '0',
  },
  {
    label: '正在导入',
    value: '1',
  },
  {
    label: '导入成功',
    value: '2',
  },
  {
    label: '导入失败',
    value: '3',
  },
  {
    label: '导入部分失败',
    value: '4',
  },
  {
    label: '网络异常',
    value: '5',
  },
];

class ExportListModel {
  constructor(search: string) {
    if (search) {
      try {
        const params = qs.parse(search.split('?')[1]);
        this.showMenu = String(params.type) || 'export';
      } catch (e) {
        console.error(e);
      }
    }
  }

  @observable public showMenu = 'export'; // 展示菜单

  public exportStore = new ExportStore({ parent: this });

  @action
  private onMenuClick = (key) => {
    this.showMenu = key;
  };

  public menuList = [
    {
      name: '导出任务',
      key: 'export',
      onClick: this.onMenuClick,
    },
    // {
    //   name: '导入任务',
    //   key: 'import',
    //   onClick: this.onMenuClick,
    // },
  ];

  // 获取配置项
  @action
  public getConfig = async (): Promise<void> => {
    this.searchListStore.programme.filterItems.addDict({
      exportStatus: exportStatusList,
      dateValue: dateList,
    });
    const res: BaseData<Config[]> = await request({ url: '/resource/back/export/config/select/all/items' });
    const list = res.data.map((el) => ({
      label: el.fileName,
      value: el.exportType,
    }));
    this.searchListStore.programme.filterItems.addDict({ exportType: list });
  };

  // 删除
  @action
  public delete = (id: number): void => {
    Modal.confirm({
      content: '确定删除吗?',
      okText: '确认',
      cancelText: '取消',
      onOk: async () => {
        await request({ url: `/resource/back/export/task/delete/${id}` });
        message.success('删除成功');
        this.searchListStore.grid.gridModel.onRefresh();
      },
    });
  };

  // 批量删除
  @action public batchDelete = (): void => {
    const ids = Array.from(this.searchListStore.grid.gridModel.selectedIds);
    if (ids.length >= 1) {
      Modal.confirm({
        content: '确认删除吗?',
        okText: '确认',
        cancelText: '取消',
        onOk: async () => {
          await request({
            url: '/resource/back/export/task/batch/delete',
            method: 'post',
            data: { ids: ids.join(',') },
          });
          message.success('删除成功');
          this.searchListStore.grid.gridModel.onQuery();
        },
      });
    } else {
      message.warning({ content: '请选择删除任务' });
    }
  };

  @action
  public batchDownload = async (): Promise<void> => {
    const selectRows = this.searchListStore.grid.gridModel.selectRows;
    const isAchieve = selectRows.every((item) => item.exportStatus === 2);
    if (selectRows.length === 0) {
      message.warning({ content: '请选择需下载任务' });
      return;
    }
    if (!isAchieve) {
      message.error('存在未导出完成的任务，请重新选择');
      return;
    }
    const zip = new JSZip();
    const blob = [];
    selectRows.forEach((item) => {
      if (item.fileUrl) {
        blob.push(
          request<Blob>({
            method: 'GET',
            withCredentials: false,
            url: item.fileUrl,
            responseType: 'blob',
          }),
        );
      }
    });
    try {
      const blobList = await Promise.all(blob);
      blobList.forEach((blob, index) => {
        let name: any = decodeURI(selectRows[index]?.fileUrl)?.split('/');
        name = name[name.length - 1];
        zip.file(name, blob);
      });
      const content = await zip.generateAsync({
        type: 'blob',
        compression: 'DEFLATE',
        compressionOptions: { level: 9 },
      });
      const url = URL.createObjectURL(content);
      window.open(url);
    } catch (e) {
      console.error(e);
    }
  };

  // 转换时间
  private convertDateTime = (dateValue: string): StartAndEndTime => {
    const FORMAT = 'YYYY-MM-DD HH:mm:ss';
    if (!dateValue) {
      return {
        startTime: moment().subtract(2, 'days').startOf('day').format(FORMAT),
        endTime: moment().endOf('day').format(FORMAT),
      };
    }
    if (dateValue === 'today') {
      return {
        startTime: moment().startOf('day').format(FORMAT),
        endTime: moment().endOf('day').format(FORMAT),
      };
    }
    if (dateValue === 'yesterday') {
      return {
        startTime: moment().subtract(1, 'days').startOf('day').format(FORMAT),
        endTime: moment().subtract(1, 'days').endOf('day').format(FORMAT),
      };
    }
    if (dateValue === 'dayBeforeYesterday') {
      return {
        startTime: moment().subtract(2, 'days').startOf('day').format(FORMAT),
        endTime: moment().subtract(2, 'days').endOf('day').format(FORMAT),
      };
    }
    return {
      startTime: '',
      endTime: '',
    };
  };

  private buttons = [
    {
      text: '批量删除',
      icon: 'icon-delete',
      handleClick: () => {
        this.batchDelete();
      },
      display: (rows) => rows.length >= 1,
    },
    {
      text: '批量下载',
      icon: 'icon-dbpldq2',
      handleClick: () => {
        this.batchDownload();
      },
    },
    // {
    //   text: '测试导出',
    //   icon: 'icon-dbpldq2',
    //   handleClick: () => {
    //     this.handleExportProduct();
    //   },
    // },
  ];

  // 处理导出
  // public handleExportProduct = (): void => {
  //   const selectedIds = Array.from(this.searchListStore.grid.gridModel.selectedIds);
  //   const queryParam = this.searchListStore.programme.filterItems.params;
  //   const queryParamShow = this.searchListStore.programme.filterItems.translateParams;
  //
  //   if (selectedIds.length === 0) {
  //     Modal.confirm({
  //       title: '提示',
  //       content: '未选择数据将导出全部数据?',
  //       onOk: () => {
  //         this.exportStore.onShow('组合商品信息', 'tiezi_withdraw_order', '', {}, queryParamShow.join(' '), queryParam);
  //       },
  //     });
  //   } else {
  //     this.exportStore.onShow('组合商品信息', 'tiezi_withdraw_order', selectedIds.join(','), {}, queryParamShow.join(' '), queryParam);
  //   }
  // };

  // 第一次查询
  public getData = (): void => {
    this.searchListStore.grid.gridModel.onQuery();
  };

  public filterset: Partial<NormalProgrammeParams> = {
    filterItems: [
      {
        field: 'dateValue',
        type: 'select',
        label: '创建时间',
        value: 'today',
      },
      {
        type: 'input',
        label: '任务编号',
        field: 'exportTaskNo',
      },
      {
        type: 'select',
        label: '导出进度',
        field: 'exportStatus',
      },
      {
        type: 'select',
        label: '导出类型',
        field: 'exportType',
      },
    ],
  };

  public grid: IMainSubStructureModel = {
    buttons: this.buttons,
    grid: {
      showEmpty: true,
      columns: [
        {
          key: 'operation',
          name: '操作',
          width: 180,
          frozen: true,
          formatter: ({ row }) => (
            <>
              {row.exportStatus === 2 && (
                <Button
                  className={styles.vendorOptBtns}
                  onClick={() => window.open(row.fileUrl)}
                  type="link"
                >
                  下载文件
                </Button>
              )}
              <Button
                className={styles.vendorOptBtns}
                onClick={this.delete.bind(this, row.id)}
                type="link"
              >
                删除
              </Button>
            </>
          ),
        },
        {
          key: 'exportTaskNo',
          name: '任务编号',
          width: 200,
        },
        {
          key: 'fileName',
          name: '导出文件名',
          width: 200,
        },
        {
          key: 'exportTypeValue',
          name: '导出类型',
          width: 200,
        },
        {
          key: 'exportCondParamShow',
          name: '导出条件',
          width: 400,
          formatter: ({ row }) => <Tooltip title={row.exportCondParamShow}>{row.exportCondParamShow}</Tooltip>,
        },
        {
          key: 'createTime',
          name: '创建时间',
          width: 180,
        },
        {
          key: 'exportStatus',
          name: '导出进度',
          formatter: ({ row }) => {
            if (row.exportStatus === 1) {
              return (
                <Progress
                  className={styles.progress}
                  percent={row.exportProcess ? row.exportProcess : 0}
                  showInfo={false}
                  strokeWidth={6}
                />
              );
            } else if (row.exportStatus === 2) {
              return <span className={styles.clgreen}>{row.exportStatusValue}</span>;
            } else if (row.exportStatus === 3 || row.exportStatus === 4) {
              return <span className={styles.clred}>{row.exportStatusValue}</span>;
            } else {
              return row.exportStatusValue;
            }
          },
        },
      ].map((col) => ({
        ...col,
        resizable: true,
        draggable: true,
      })),
      rows: [],
      gridIdForColumnConfig: 'tsEgenieTsBaseinfoExportListExportTable',
      setColumnsDisplay: true,
      primaryKeyField: 'id',
      sortByLocal: false,
      showCheckBox: true,
    },
    hiddenSubTable: true,
    api: {
      onQuery: (params) => {
        const { filterParams, ...rest } = params;
        const { dateValue, exportStatus, ...restParms } = filterParams;
        let startTime = '';
        let endTime = '';
        if (dateValue) {
          const time = this.convertDateTime(dateValue);
          startTime = time.startTime;
          endTime = time.endTime;
        }
        return request({
          url: `/resource/back/export/task/page`,
          method: 'POST',
          data: {
            startTime,
            endTime,
            exportStatus: exportStatus ? Number(exportStatus) : null,
            ...restParms,
            ...rest,
          },
        });
      },
    },
  };

  public searchListStore = new SearchListModal({
    programme: this.filterset,
    grid: this.grid,
  });
}

/**
 * 导入model
 */
class ImportListModel {
  // 获取配置项
  @action
  public getImportConfig = async (): Promise<void> => {
    this.importSearchListStore.programme.filterItems.addDict({
      importStatus: importStatusList,
      dateValue: dateList,
    });

    const res: BaseData<ImportConfig[]> = await request({ url: '/api/boss/baseinfo/rest/imports/getImportTypeList' });

    const list = res.data.map((el) => ({
      label: el.templateName,
      value: el.importType,
    }));
    this.importSearchListStore.programme.filterItems.addDict({ importType: list });
  };

  // 删除
  @action
  public delete = (id: number): void => {
    Modal.confirm({
      content: '确定删除吗?',
      okText: '确认',
      cancelText: '取消',
      onOk: async () => {
        await request({
          method: 'POST',
          url: '/api/boss/baseinfo/rest/imports/getImportTypeList',
          data: { ids: `${id}` },
        });
        message.success('删除成功');
        this.importSearchListStore.grid.gridModel.onRefresh();
      },
    });
  };

  // 转换时间
  private convertDateTime = (dateValue: string): StartAndEndTime => {
    const FORMAT = 'YYYY-MM-DD HH:mm:ss';
    if (!dateValue) {
      return {
        startTime: moment().subtract(2, 'days').startOf('day').format(FORMAT),
        endTime: moment().endOf('day').format(FORMAT),
      };
    }
    if (dateValue === 'today') {
      return {
        startTime: moment().startOf('day').format(FORMAT),
        endTime: moment().endOf('day').format(FORMAT),
      };
    }
    if (dateValue === 'yesterday') {
      return {
        startTime: moment().subtract(1, 'days').startOf('day').format(FORMAT),
        endTime: moment().subtract(1, 'days').endOf('day').format(FORMAT),
      };
    }
    if (dateValue === 'dayBeforeYesterday') {
      return {
        startTime: moment().subtract(2, 'days').startOf('day').format(FORMAT),
        endTime: moment().subtract(2, 'days').endOf('day').format(FORMAT),
      };
    }
    return {
      startTime: '',
      endTime: '',
    };
  };

  // 第一次查询
  public getImportData = (): void => {
    this.importSearchListStore.grid.gridModel.onQuery();
  };

  public filterset: Partial<NormalProgrammeParams> = {
    filterItems: [
      {
        field: 'dateValue',
        type: 'select',
        label: '创建时间',
        value: 'today',
      },
      {
        type: 'input',
        label: '任务编号',
        field: 'importTaskNo',
      },
      {
        type: 'select',
        label: '导入进度',
        field: 'importStatus',
      },
      {
        type: 'select',
        label: '导入类型',
        field: 'importType',
      },
    ],
  };

  public batchDelete = () => {
    const ids = Array.from(this.importSearchListStore.grid.gridModel.selectedIds);
    if (ids.length >= 1) {
      Modal.confirm({
        content: '确认删除吗?',
        okText: '确认',
        cancelText: '取消',
        onOk: async () => {
          await request({
            url: '/api/boss/baseinfo/rest/imports/getImportTypeList',
            method: 'post',
            data: { ids: ids.join(',') },
          });
          message.success('删除成功');
          this.importSearchListStore.grid.gridModel.onQuery();
        },
      });
    } else {
      message.warning({ content: '请选择删除任务' });
    }
  };

  public grid: IMainSubStructureModel = {
    buttons: [
      {
        text: '批量删除',
        icon: 'icon-delete',
        handleClick: () => {
          this.batchDelete();
        },
        display: (rows) => rows.length >= 1,
      },
    ],
    grid: {
      showEmpty: true,
      columns: [
        {
          key: 'operation',
          name: '操作',
          resizable: true,
          width: 240,
          frozen: true,
          formatter: ({ row }) => (
            <>
              {[3, 4].some((age) => age === row.importStatus) && (
                <Button
                  className={styles.vendorOptBtns}
                  onClick={() => window.open(row.errorUrl)}
                  type="link"
                >
                  下载导入失败文件
                </Button>
              )}
              {row.importStatus !== 1 && (
                <Button
                  className={styles.vendorOptBtns}
                  onClick={this.delete.bind(this, row.id)}
                  type="link"
                >
                  删除
                </Button>
              )}
            </>
          ),
        },
        {
          key: 'serialNo',
          name: '任务编号',
          width: 200,
        },
        {
          key: 'importTypeName',
          name: '导入类型',
          width: 200,
        },
        {
          key: 'importParamShow',
          name: '导入参数',
          width: 400,
          resizable: true,
          formatter: ({ row }) => <Tooltip title={row.importParamShow}>{row.importParamShow}</Tooltip>,
        },
        {
          key: 'createdAt',
          name: '创建时间',
          width: 200,
        },
        {
          key: 'importStatus',
          name: '导入进度',
          width: 200,
          formatter: ({ row }) => {
            let importStatusName = '';
            let className = '';
            importStatusList.forEach((item) => {
              if (item.value == `${row.importStatus}`) {
                importStatusName = item.label;
              }
            });
            switch (row.importStatus) {
              case 2: {
                className = 'clgreen';
                break;
              }
              case 3: {
                className = 'clred';
                break;
              }
              case 5:
              case 4:
                className = 'warning';
                break;
            }
            return row.importStatus !== 1 ? (
              <span className={styles[className]}>{importStatusName}</span>
            ) : (
              <Progress
                className={styles.progress}
                percent={row.percent ? Number(row.percent) : 0}
                showInfo={false}
                strokeWidth={6}
              />
            );
          },
        },
      ].map((col) => ({
        ...col,
        resizable: true,
        draggable: true,
      })),
      rows: [],
      primaryKeyField: 'id',
      gridIdForColumnConfig: 'tsEgenieTsBaseinfoExportListImportTable',
      setColumnsDisplay: true,
      sortByLocal: false,
      showCheckBox: true,
    },
    hiddenSubTable: true,
    api: {
      onQuery: (params) => {
        const { filterParams, ...rest } = params;
        const { dateValue, exportStatus, ...restParms } = filterParams;
        let startTime = '';
        let endTime = '';
        if (dateValue) {
          const time = this.convertDateTime(dateValue);
          startTime = time.startTime;
          endTime = time.endTime;
        }
        return request({
          url: `/api/boss/baseinfo/rest/imports/getImportTypeList`,
          method: 'POST',
          data: {
            startTime,
            endTime,
            ...restParms,
            ...rest,
          },
        });
      },
    },
  };

  public importSearchListStore = new SearchListModal({
    programme: this.filterset,
    grid: this.grid,
  });
}

export { ExportListModel, ImportListModel };
