import { Layout, Menu } from 'antd';
import { SearchListStructure } from '../../utils/searchListStructure';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import styles from './index.less';
import { ExportListModel, ImportListModel } from './store';
import { ExportModal } from '../../compnents/exportModal';

const { Sider, Content } = Layout;
const { Item } = Menu;
const ExportList = observer((props): JSX.Element => {
  const store = React.useMemo(() => new ExportListModel(props.location?.search || ''), [props.location?.search]);
  const importStore = React.useMemo(() => new ImportListModel(), []);
  const { searchListStore, getConfig, getData, showMenu, menuList } = store;
  const { importSearchListStore, getImportConfig, getImportData } = importStore;

  useEffect(() => {
    getConfig();
    getData();
  }, [getConfig, getData]);
  // useEffect(() => {
  //   getImportConfig();
  //   getImportData();
  // }, [getImportConfig, getImportData]);

  return (
    <Layout className={styles.layout}>
      <Sider
        style={{ padding: '8px 0' }}
        theme="light"
        width={160}
      >
        <Menu
          items={((): any[] =>
            menuList.map((item) => ({
              label: item.name,
              key: item.key,
              onClick: () => {
                item.onClick(item.key);
              },
            })))()}
          mode="inline"
          selectedKeys={[showMenu]}
        />
      </Sider>
      <Content style={{ margin: '0 12px' }}>{showMenu === 'export' ? <SearchListStructure store={searchListStore} /> : <SearchListStructure store={importSearchListStore} />}</Content>
      <ExportModal store={store?.exportStore} />
    </Layout>
  );
});

export default ExportList;
